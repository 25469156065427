@media only screen and (max-width: 960px) {
  .footer-container {
    bottom: 7% !important;
    text-align: left;
  }
  .footer-container .wrapper {
    margin-left: 0 !important;
  }
}

.footer-container {
  position: fixed;
  bottom: 0;
  top: unset;
  left: 0;
  width: 100%;
  margin: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.farming-container {
  padding: 0rem;
  border-radius: 20px;
  margin-top: 2.4rem;
}
.dark .farming-container {
  /* background-color: #192230; */
  color: white;
}
.light .farming-container {
  background-color: transparent;
  color: black;
}

.farming-item {
  padding: 1.48rem 1.48rem;
  border-radius: 20px;
  max-height: 104px;
  overflow: hidden;
}
.dark .farming-item {
  border: solid 2px #1f1f2e;
  background-color: #1f1f2e;
}

.farming-item:hover {
  border: solid 2px #d44c61;
}

.farming-item.active:hover {
  border: solid 2px #374151;
}

.light .farming-item {
  border: solid 1px #d5d5d5;
  background-color: white;
}

.farming-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.farming-footer .text-liquidity a {
  color: #d44c61 !important;
  margin-right: 1.4rem;
  font-weight: bold;
}

.farming-footer .reward {
  display: flex;
  align-items: center;
}

.btn-claim-reward {
  margin-left: 4px;
  margin-top: 0 !important;
  width: 100%;
  padding: 0.48rem 1.68rem;
  border-radius: 10px;
  outline: none;
  border: 0;
  color: white;
  font-weight: bold;
  background-color: #ab0c23;
}

.text-tvl-title {
  margin-bottom: 0.88rem;
  padding-left: 0.28rem;
}

.text-tvl {
  color: #ef1736;
  font-size: 3rem;
}

.btn-claim-reward.disabled {
  background-color: rgb(64, 68, 79);
  color: rgb(195, 197, 203);
  cursor: auto;
  box-shadow: none;
  border: 1px solid transparent;
  outline: none;
  opacity: 1;
}

.farming-footer .reward .title {
  font-weight: bold;
}
.farming-footer .reward .value {
  font-weight: bold;
  color: #d44c61;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}

.farming-footer .reward .desc {
  font-weight: 500;
  color: rgb(145, 144, 143);
  margin-right: 0.4rem;
}

.farming-divider {
  margin: 2rem 0;
  border-top: 1px dashed #374151 !important;
}

.dark .farming-divider {
  border-color: #374151;
}
.light .farming-divider {
  border-color: #d1d1d1;
}

.farming-item.active {
  max-height: 800px;
}

.farming-header {
  cursor: pointer;
}

.farming-title {
  padding: 0px 1.48rem;
  font-weight: bold;
  color: #ef1736;
}

.tokens-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.weight-badge {
  padding: 0.4rem 0.8rem;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  background-color: #ff7f00;
}

.apr-data {
  text-align: left;
  justify-content: center;
  align-items: left;
  padding-top: 4px;
}

.apr-data .title {
  font-size: 0.9rem;
  color: rgb(145, 144, 143);
  margin-right: 4px;
}

.apr-data .value {
  font-size: 0.9rem;
  font-weight: bold;
}

.tokens-group .title-group {
  padding-left: 0.8rem;
  padding-top: 4px;
}

.dark .tokens-group .text-title {
  color: white;
}
.light .tokens-group .text-title {
  color: black;
}

.tokens-group .text-sub {
  font-size: 0.8rem;
  color: #808285;
}

.apr-data .sub {
  font-size: 0.8rem;
  color: #808285;
}

.tvl-data {
  display: flex;
  align-items: center;
  justify-content: left;
  font-weight: bold;
}

.deposited-data {
  display: flex;
  align-items: center;
  justify-content: left;
  font-weight: bold;
}

.earned-group {
  display: flex;
  align-items: center;
  justify-content: left;
  color: #d44c61;
  font-weight: bold;
}

.farming-body {
  display: flex;
}
.farming-reward {
  text-align: left;
}
.farming-reward .title {
  color: #d44c61;
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 2.88rem;
}

.farming-reward .sub {
  color: #808285;
  margin-bottom: 1.8rem;
}

.farming-input {
  padding: 0.6rem 0.8rem;
  border-radius: 10px;
  border: solid 1px #374151;
  background-color: #192230;
  width: 100%;
  margin: 0.48rem 0rem;
  margin-bottom: 1.4rem;
  color: white;
}

.dark .farming-input {
  border: solid 1px #374151;
  background-color: #192230;
  color: white;
}

.light .farming-input {
  border: solid 1px #d1d1d1;
  background-color: #f3f4f6;
  color: black;
}

.farming-input::placeholder {
  color: #808285 !important;
  opacity: 1; /* Firefox */
}

.farming-input:focus {
  outline: none;
}

.btn-max {
  position: relative;
  float: right;
  z-index: 20;
  margin-top: -3.6rem;
  margin-right: 0.58rem;
  color: #d44c61;
  background-color: transparent;
  border: 0;
  outline: 0;
  font-weight: bold;
  font-size: 1rem;
}

.btn-claim {
  width: 100%;
  padding: 0.6rem 4rem;
  border-radius: 10px;
  outline: none;
  border: 0;
  color: white;
  font-weight: bold;
  background-color: #ab0c23;
}

.btn-farming-confirm.disabled {
  border-radius: 10px;
  border: solid 1px #808285;
  color: #808285;
  background-color: transparent;
  background-image: unset;
  cursor: unset !important;
}

.btn-claim.disabled {
  border-radius: 10px;
  border: solid 1px #808285;
  color: #808285;
  background-color: transparent;
  background-image: unset;
  cursor: unset !important;
}

.btn-farming-confirm {
  margin-top: 0 !important;
  width: 100%;
  padding: 0.6rem 0;
  border-radius: 10px;
  outline: none;
  border: 0;
  color: white;
  font-weight: bold;
  background-color: #ab0c23;
}

.gap-1 {
  gap: 0.4rem;
}

.text-balance {
  color: #d44c61;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.dark .modal-loading {
  color: white;
}
.light .modal-loading {
  color: black;
}

.modal-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  width: 100%;
}

.text-black {
  color: black;
}

.text-white {
  color: white;
}

.text-red {
  color: red;
}

.reward-earn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.light .reward-earn .title {
  color: black;
}

.dark .reward-earn .title {
  color: white;
}

.light .reward-earn .body {
  color: black;
}

.dark .reward-earn .body {
  color: white;
}

.reward-panel.reward-earn {
  padding: 60px !important;
}

.reward-panel {
  padding: 30px !important;
  border-radius: 30px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
}

.light .reward-panel {
  background-color: white;
}

.dark .reward-panel {
  background-color: #1f2937;
}

.reward-earn .title {
  color: black;
}

.reward-earn .body {
  font-weight: bold;
  font-size: 2rem;
  margin: 1rem 0rem;
}

.reward-earn .sub {
  color: #9ca3af;
  font-size: 0.8rem;
}

.locked-amount .title {
  font-weight: bold;
  margin-bottom: 0.48rem;
  font-size: 1.08rem;
}

.dark .locked-amount .title {
  color: white;
}

.light .locked-amount .title {
  color: black;
}

.locked-amount .sub {
  color: #9ca3af;
}

.text-green {
  color: #d44c61;
}

@media only screen and (max-width: 600px) {
  .text-tvl {
    font-size: 2rem;
  }
  .container.farm {
    padding: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .farming-container {
    padding-left: 0.4rem !important;
    padding-right: 0.4rem !important;
  }
  .farming-footer .text-liquidity a {
    color: #d44c61 !important;
    margin: 1rem 0.7rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }

  .farming-title {
    padding: 0 1.3rem 0 1rem;
    font-size: 0.8rem;
  }

  .tokens-group .text-title {
    font-size: 0.6rem;
  }

  .tokens-group .title-group .text-sub {
    font-size: 0.5rem;
  }

  .farming-item {
    padding: 0.2rem;
    max-height: 106px;
  }
  .farming-reward .title {
    margin-top: 1.2rem !important;
  }

  .farming-reward .sub {
    margin-bottom: 0.6rem !important;
  }

  .tokens-group .img-logo img {
    height: 2rem !important;
  }

  .weight-badge {
    padding: 0.1rem 0.2rem;
    border-radius: 6px;
    font-size: 0.6rem;
  }

  .farming-header .title-group {
    padding-left: 0.2rem;
    padding-top: 0px;
  }
  .tokens-group {
    padding-right: 0 !important;
  }

  .apr-data .title {
    font-size: 0.6rem;
  }
  .apr-data .sub {
    font-size: 0.5rem;
  }

  .apr-data {
    display: flex;
    justify-content: center;
    align-items: left;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .deposited-data {
    font-size: 0.6rem;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .tvl-data {
    font-size: 0.6rem;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .earned-group {
    font-size: 0.6rem;
    padding-left: 0 !important;
  }
  .farming-header {
    height: 4rem;
    padding-top: 0.48rem;
  }
  .farming-footer .reward {
    justify-content: center;
    margin-bottom: 1rem;
  }

  .farming-divider {
    margin: 3.4rem 0;
    margin-bottom: 1.8rem;
  }

  .farming-footer {
    display: block;
    text-align: center;
    width: 100%;
  }

  .text-liquidity {
    justify-content: center;
  }
}
